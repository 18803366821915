import logo from '../../assets/images/hwinyen.svg';

function LogoIcon(){
  return(
    <div>
      <img src={logo} alt="Personal Logo Icon" />
    </div>
  );
}

export default LogoIcon;